import React from 'react';
import {
    Button,
    Input,
    Form,
    message
} from 'antd';
import Ajax from '../utils/Ajax';
import '../less/index.less'

const FormItem = Form.Item;
const { Search } = Input;

const language = {
    'zh': {
        title: "注销账号",
        phonePlaceholder: "请输入手机号或者邮箱",
        codePlaceholder: "请输入验证码",
        getCode: "获取验证码",
        logoutButton: "注销账号",
        explain: "说明",
        explainText: "您也可以在应用内删除您的账户：打开App，进入“我的-账号与安全-注销账号”。",
        onOk: "确认",
        onCancel: "取消",
        logoutSuccessTip: "注销成功",
        uuidErrorTip: "请获取验证码",
        logoutFailTip: "注销失败",
        accountNo: "账号不存在"
    },
    'zh-CN': {
        title: "注销账号",
        phonePlaceholder: "请输入手机号或者邮箱",
        codePlaceholder: "请输入验证码",
        getCode: "获取验证码",
        logoutButton: "注销账号",
        explain: "说明",
        explainText: "您也可以在应用内删除您的账户：打开App，进入“我的-账号与安全-注销账号”。",
        onOk: "确认",
        onCancel: "取消",
        logoutSuccessTip: "注销成功",
        uuidErrorTip: "请获取验证码",
        logoutFailTip: "注销失败",
        accountNo: "账号不存在"
    },
    'en': {
        title: "Cancel Account",
        phonePlaceholder: "Please enter your phone number or email address",
        codePlaceholder: "Please enter the verification code",
        getCode: "Obtain verification code",
        logoutButton: "Cancel Account",
        explain: "explain",
        explainText: "You can also delete your account in the App: Open the App and go to 'Me - Account Security - Cancel account'.",
        onOk: "OK",
        onCancel: "Cancel",
        logoutSuccessTip: "Cancel Account Success",
        uuidErrorTip: "Please get the verification code",
        logoutFailTip: "Cancel Account Fail",
        accountNo: "The account does not exist"
    }
    
}

class LogOut extends React.Component {

    constructor() {
        super();
        let lang = navigator.languages[0];
        
        let langObj = language[lang] || language.en;
        this.state = {
            langObj,
            uuid: '',
            loading: false
        }
        
    }

    uformRef = React.createRef();
    sformRef = React.createRef();

    sendLoginMSM = () => {
        let receiver = this.sformRef.current.getFieldValue('receiver');
        let { langObj } = this.state;

        if (!receiver) {
            message.error(langObj.phonePlaceholder);
            return ;
        }

        this.setState({
            loading: true
        });

       this.dowmTime();

        Ajax('/v2/sso/cancel_user/sendCode',{ receiver }).then((res) => {
            this.setState({
                uuid: res.data
            });

            this.dowmTime(30000);
        })
    }

    dowmTime(time = 10000) {
        clearTimeout(this.time);
        this.time = setTimeout(() => {
            this.setState({
                loading: false
            });
        }, time);
    }


    onFinish = (value) => {
        let { langObj, uuid } = this.state;
        if (!value.receiver) {
            message.error(langObj.phonePlaceholder);
            return ;
        }

        if (!uuid) {
            message.error(langObj.uuidErrorTip);
            return ;
        }

        if (!value.verifyCode) {
            message.error(langObj.codePlaceholder);
            return ;
        }

        value.uuid = uuid;
        Ajax('/v1/sso/cancelUser',value).then((res) => {
            if(res.code === 341) {
                message.error(langObj.accountNo);
            } else if (res.code != 200 ) {
                message.error(langObj.logoutFailTip);
            } else {
                message.success(langObj.logoutSuccessTip);
            }
           
        })
    }
    

    render() {
        let { langObj, loading } = this.state;

        return (
            <div className='logout'>
                <div className='logout-box'>
                <div className='logout-box__title'>{langObj.title}</div>
                    <div className='logout-box__body'>
                        <Form onFinish={this.onFinish} ref={ this.sformRef }>
                            <FormItem name='receiver'>
                                <Input
                                    size="large"
                                    placeholder={langObj.phonePlaceholder}
                                />
                            </FormItem>
                            <FormItem name='verifyCode'>
                                <Search
                                    placeholder={langObj.codePlaceholder}
                                    enterButton={langObj.getCode}
                                    size="large"
                                    onSearch={ this.sendLoginMSM }
                                    loading={ loading }
                                    />
                            </FormItem>
                            <Button  type='primary' htmlType='submit'>{langObj.logoutButton}</Button>
                        </Form>
                    </div>
                </div>
                <div className='explain'>
                    <div>{langObj.explain}:</div>
                    <div>{langObj.explainText}</div>
                </div>
            </div>
        )
    }
}

export default LogOut;