/*
 * @Author: your name
 * @Date: 2020-02-19 15:08:45
 * @LastEditTime: 2021-10-21 17:10:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /wx_o2o/src/requestConfig/Ajax.ts
 */ 
import qs from 'qs';
import axios from 'axios';
let tokenKey = getToken();
let isMock = true;
import {
    message
} from 'antd';

export function getToken(){
    let arr = document.cookie.split(';')
    let reg = /token=/
    let token;
    arr.map((item, i) => {
        let arr1 = item.match(reg)
        if (arr1) {
            let arr2 = item.split('=')
            token = arr2.length > 1 ? arr2[1].split('&')[0] : ''
        }
    })

    return token || '';
}

//保存token
function saveCookie(token){
    let expires = new Date()

    if( token ){
        expires.setTime(expires.getTime() +  24*60*60*1000);
    }else{
        expires.setTime(expires - 1000);
    }

	document.cookie = "token="+token+";expires="+expires.toGMTString();
}

const saveToken = function(token){

    (token === '' || token) && ( tokenKey = token ) && ( saveCookie(token) );

    return tokenKey;
}



//错误提示/过滤
const errFilterOrTip = function(result,isSuccessTip,isFailTip){

    result = isMock&&result.data || result;
    
    let errCode = result.errCode;
    let token = result.token;

    switch(errCode){
        case 300:
            saveCookie(token);
            document.cookie = '';
            tokenKey = '';
            window.location.href='/#/login';
            break;
        case 500:
        case 400:
        case 11001:
            isFailTip&&message.error(result.errMsg);
            return Promise.reject();
        default:
            if(!!result.token){
                saveToken(result.token);
            } 
     
            isSuccessTip&&message.success(result.errMsg);
            return result
    }
}

const instance = axios.create({
    baseURL: 'http://192.168.31.124:8082/',
    //baseURL: 'https://openapi.sjychina.com/mall',
    //baseURL: 'http://openapi.sjyprt.com/mall',
    timeout: 60000
});

instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
  }, function (error) {
    // 对响应错误做点什么

 
    if(error.response&&error.response.status === 400 && error.response.data.code === 500){
        message.error(error.response.data.msg);
    }else{
        message.error('网络连接异常，请稍后再试');
    }
    
    return Promise.reject(error);
  });


export default async function(url,data = {},method = 'post'){

    let headers = data.isJson
        ? { 'Content-Type': 'application/json' }
        : { 'Content-Type': 'application/x-www-form-urlencoded' };

    // if(saveToken()){
    //     data.token = saveToken();
    // }else{
    //     window.location.href='/#/login';
    // }
       
    // if(!instance.defaults.headers.common.Authorization && saveToken()){

    //     instance.defaults.headers.common['Authorization'] = saveToken();
    // }

    let urlArr = url.split('/');
    console.log(urlArr);

	switch (urlArr[1]) {
		case 'sso':
		case "admin": instance.defaults.baseURL = "https://openapi.lingmoyun.com/SjyCloudManage-sso-web";break;
		// case "admin": instance.defaults.baseURL = "https://openapi.lingmoyun.cn/SjyCloudManage-sso-web/";break;
		//case 'admin': instance.defaults.baseURL = 'http://192.168.31.83:8082/';break
		case 'o2o':
		case 'finance':
		case "equipment": instance.defaults.baseURL = "https://openapi.lingmoyun.com/platform/official-website-svr";break;
		case "v1": 
		case "v2": instance.defaults.baseURL = "https://openapi.lingmoyun.com/jyb/print-web-wxma/";break;
		// case "equipment": instance.defaults.baseURL = "https://openapi.lingmoyun.cn/SjyCloudManage-equipment-web/";break;
		//case 'equipment': instance.defaults.baseURL = 'http://192.168.31.83:8085/';break;
		// case 'quart':
		// 	urlhttp = 'https://openapi.lingmoyun.com/SjyCloudManage-quartz';break
    }

        
    let isSuccessTip = data.isSuccessTip || false;
    let isFailTip = typeof data.isFailTip === "boolean" ? data.isFailTip : true;

    data.isFormData || data.isJson || (data = qs.stringify(data));

    data.isJson && delete data.isJson;

    if(data.isFormData){
        delete data.isFormData;

        let formData = new FormData();

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key];
                
                formData.append(key,element);
            }
        }

        data = formData;
    }

    let result = await instance({
        method,
        url,
        data,
        headers
    }); 

    return await errFilterOrTip(result,isSuccessTip,isFailTip);
}



  

