import React, { Component } from 'react';
import '../less/index.less'

class AboutUs extends Component {
    constructor(props) {
		super(props)
    }
    
    render() {
        return (
            <div className="l-font-title">
                { this.props.children }
			</div>
        )
    }
}

export default AboutUs;