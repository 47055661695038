import React from "react";
import '../less/index.less';
import img1 from '../images/s_1.png';
import img2 from '../images/s_2.png';
import img3 from '../images/s_3.png';
import img4 from '../images/s_4.png';
import img5 from '../images/s_5.png';
import img6 from '../images/s_6.png';

class SoftCenter extends React.Component {
    goOpenDownload() {
        window.open('http://open.lingmoyun.com/#/toolDownload')
    }

    render() {
        return <div className="soft-center">
            <img src={img1}/>
            <img src={img2}/>
            <img src={img3}/>
            <img src={img4}/>
            <img src={img5}/>
            <img src={img6} onClick={() => this.goOpenDownload()}/>
        </div>;
    }
}

export default SoftCenter