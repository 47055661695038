import React, { Component } from 'react';
import { Button } from 'antd'; 
import '../less/index.less'
import shop from '../images/shop.png';
import open from '../images/open.png';
import documentCenter from '../images/document-center.png';
import softCenter from '../images/softcenter.png';
import imgText from '../images/img-text.png';
import imgText1 from '../images/img-text1.png';
import product from '../images/product.png';
import soft from '../images/soft.png';
import stuff from '../images/stuff.png';
import study from '../images/study.png';
import yunying from '../images/yunying.png';
import kefu from '../images/kefu.png';
import aboutus from '../images/aboutus.png';
import c1 from '../images/c1.png';
import c2 from '../images/c2.png';
import c3 from '../images/c3.png';
import c4 from '../images/c4.png';
import c5 from '../images/c5.png';
class Home extends Component {

    goTaobao() {
        window.open('https://wumobg.tmall.com/');
        
    }

    goOpen() {
        window.open('http://open.lingmoyun.com/#/home');
    }

    goOpenDoc() {
        window.open('http://open.lingmoyun.com/#/documentContent/72');
    }

    goSoftCenter() {
        this.props.history.push(
            {
                pathname:'/softCenter'
            }
        );
    }
    render() {

        let { productList:list } = this.props;

        return (
            <div className="home">
                <div className='tab'>
                    <div className='tab-item'>
                        <img src={shop} alt="" onClick={this.goTaobao}/>
                    </div>
                    <div className='tab-item'>
                        <img src={open} alt="" onClick={this.goOpen}/>
                    </div>
                    <div className='tab-item'>
                        <img src={documentCenter} alt="" onClick={this.goOpenDoc}/>
                    </div>
                    <div className='tab-item'>
                        <img src={softCenter} alt="" onClick={() => this.goSoftCenter()}/>
                    </div>
                </div>
                <div className='img-text'>
                    <div className='title-pre'>无墨热敏打印技术创新者</div>
                    <div className='title'>独创无墨热敏打印技术，10秒极速打印</div>
                    <div className='title-desc'>零墨云率先将软件结合打印的方式，极大程度提高了打印效率，打印机体积小，使用灵活，降低了对打印地点等限制的要求。打开软件一键打印, 打印速度是原
始打印机的10倍。独创热敏打印技术，无需更换墨盒减少对人体伤害，使打印更安全更放心。<br />
独家研发零墨云企业版开放平台，使企业无需研发打印技术不在消耗大量成本，赚钱更容易。</div>
                    <img src={imgText} alt="" />
                    <div className='img-text-footer'>
                        <div>
                            <div className='footer-pre'>零墨云企业版开放平台</div>
                            <div className='footer-title'>专属企业打印通道</div>
                            <div className='footer-desc'>支持百种模板，打印功能齐全</div>
                        </div>
                        <Button type='primary'>免费试用 &gt;</Button>
                    </div>
                </div>
                <div className='img-type'>
                    <div className='product-pre'>更全面 更高效</div>
                    <div className='product-title'>打印产品全覆盖</div>
                    <div className='tab-type'>
                        <div className='tab-item'>
                            <img src={product} alt="" />
                        </div>
                        <div className='tab-item'>
                            <img src={soft} alt="" />
                        </div>
                        <div className='tab-item'>
                            <img src={stuff} alt="" />
                        </div>
                    </div>
                </div>
                <div className='img-text'>
                    <div className='title-pre'>云打印API开放平台</div>
                    <div className='title'>让打印更简单</div>
                    <div className='title-desc'>满足移动端设备（手机/平板电脑）直接打印的需求，无需再上传电脑打印，实现移动端远程打印需求，打印不受距离限制，异地门店、异地发货、外勤出差等
情况都适用。无需构建局域网，各门店跟仓库之间、大型企业的各部门之间均可共享一台云打印机打印，提效降本。服务器支持超大并发访问，满足高频、量
大的打印需求；采用防丢单技术，确保订单打印不漏单不重单。支持多种场景提供多功能API接口接入，打印排版支持HTML语言，效果所见即所得。售前售后
均有专业团队对接，全国网点提供线下硬件服务。</div>
                </div>
                <img src={imgText1} alt=""  style={{width: '100%'}}/>
                <div className='img-type'>
                    <div className='product-pre'>更全面 更高效</div>
                    <div className='product-title'>打印产品全覆盖</div>
                    <div className='tab-type'>
                        <div className='tab-item'>
                            <img src={study} alt="" />
                        </div>
                        <div className='tab-item'>
                            <img src={yunying} alt="" />
                        </div>
                        <div className='tab-item'>
                            <img src={kefu} alt="" />
                        </div>
                    </div>
                </div>
                <div className='about-us'>
                    <img src={aboutus} alt="" />
                    <div className='about-us-text'>
                        <div className='about-us-title'>关于我们</div>
                        <div className='about-us-desc'>
                            杭州零墨云科技有限公司，是一家集硬件技术研究、硬件研发、
                            云端大数据以及软件打印配套服务于一体的科技企业，创始团
                            队均来自国内知名互联网公司，拥有十余年的行业经验。
                            <br/><br/>
                            公司致力于为企业提供整体信息化解决方案和全方位家庭无线
                            打印解决方案，产品全面覆盖商用、新零售、电商以及居家使
                            用，目前服务企业已超过200家，服务范围覆盖国内及海外，
                            专业顾问为客户提供全天候、一对一的贴心服务，帮助客户解
                            决售前及售后的多种问题。
                            <br/><br/>
                            零墨云科技旗下自主研发的系列无墨打印机和家印宝打印系统
                            一直以稳定、流畅的使用体验深受客户一致认可和好评，已广
                            泛应用于电子商务、旅游票务、教育培训、服务外包、医疗保
                            健、物流零售等众多行业未来，零墨云科技将持续以创新技术
                            和真诚服务为用户提供更好的体验，助力客户的发展，为企业
                            提供更智能、更个性化的服务，与企业一道成长，共谋发展。
                        </div>
                    </div>
                </div>
                <div className='img-type' style={{paddingBottom: '85px'}}>
                    <div className='product-pre'>无论中小企业还是行业龙头</div>
                    <div className='product-title'>我们都能给你提供匹配的打印服务</div>
                    <div className='tab-data'>
                        <div className='tab-item'>
                            <p>200<span>+</span></p>
                            <p>合作品牌商家</p>
                        </div>
                        <div className='tab-item'>
                            <p>10<span>万+</span></p>
                            <p>注册用户</p>
                        </div>
                        <div className='tab-item'>
                            <p>800<span>万张+</span></p>
                            <p>打印纸张数量</p>
                        </div>
                    </div>
                </div>
                <div style={{background: '#f5f6fa'}}>
                    <div className='companys' style={{marginBottom: '32px'}}>
                        <img src={c2} alt="" style={{marginLeft: '220px'}}/>
                        <img src={c4} alt="" style={{marginLeft: '175px'}}/>
                    </div>
                    <div className='companys'>
                        <img src={c1} alt="" style={{marginLeft: '20px'}}/>
                        <img src={c3} alt="" style={{marginLeft: '270px'}}/>
                        <img src={c5} alt="" style={{marginLeft: '280px'}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;