import React, { Component } from 'react';
import '../less/index.less'
import Ajax from '../utils/Ajax';
import Title from '../components/Title';

class AboutUs extends Component {
    constructor(props) {
		super(props)
        this.state = {
            data:{}
        }
    }

    componentDidMount(){
        Ajax('sjy/equipment/officialwebsite/selectCompanyInfoTUser')
            .then((res) => {

                this.setState({
                    data:res.rows[0]
                })
                
            })
    }
    
    render() {
        let { data } = this.state;

        return (
            <div className="About">
                <div className="about-company-msg">
                    <div className="company-img">
                        <img style={{width:'100%',marginTop:'65px'}} src={data.backgroundImg} alt=""/>
                    </div>
                    <div className="company-msg">
                        <p className="biaoti">公司介绍</p>
                        <p>
                            {
                                data.business
                            }
                        </p>
                        <p className="biaoti" style={{marginBottom:'50px'}}>企业文化</p>
                        <p>
                            {
                                data.future
                            } 
                            <br />
                            {
                                data.mission
                            }
                        </p>
                    </div>
                </div>
                {/* <Title>
                    <span style={{fontWeight: 700}}>发展历程</span>
                </Title>
                <img src={ data.historyImg}/> */}
                {/* <Timeline mode="alternate">
                    <Timeline.Item color='#ccc'></Timeline.Item>
                    <Timeline.Item>
                        <span className='year'>2016</span>
                        公司成立三个boss组件团队，以打印机为主营业务
                    </Timeline.Item>
                    <Timeline.Item>
                        <span className='year'>2017</span>
                        公司成立三个boss组件团队，以打印机为主营业务
                    </Timeline.Item>
                    <Timeline.Item>
                        <span className='year'>2018</span>
                        公司成立三个boss组件团队，以打印机为主营业务
                    </Timeline.Item>
                    <Timeline.Item>
                        <span className='year'>2019</span>
                        公司成立三个boss组件团队，以打印机为主营业务
                    </Timeline.Item>
                    <Timeline.Item>
                        <span className='year'>2020</span>
                        公司成立三个boss组件团队，以打印机为主营业务
                    </Timeline.Item>
                </Timeline> */}
			</div>
        )
    }
}

export default AboutUs;