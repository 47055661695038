import React, { Component } from 'react';
import '../less/index.less'
// import Title from '../components/Title';
import CarouselPage from './carouselPage';
// import A3001 from '../images/A300/images/A300详情_01.jpg';
// import A3002 from '../images/A300/images/A300详情_02.jpg';
// import A3003 from '../images/A300/images/A300详情_03.jpg';
// import A3004 from '../images/A300/1.jpg';
// import A3005 from '../images/A300/2.jpg';
// import A3006 from '../images/A300/3.jpg';

// import MT8001 from '../images/MT800/images/汉印MT800_01.jpg';
// import MT8002 from '../images/MT800/images/汉印MT800_02.jpg';
// import MT8003 from '../images/MT800/images/汉印MT800_03.jpg';
// import MT8004 from '../images/MT800/1.jpg';
// import MT8005 from '../images/MT800/2.png';
// import MT8006 from '../images/MT800/3.jpg';

// import N311 from '../images/N31/images/汉印MT800_01.jpg';
// import N312 from '../images/N31/images/汉印MT800_02.jpg';
// import N313 from '../images/N31/images/汉印MT800_03.jpg';
// import N314 from '../images/N31/1.jpg';
// import N315 from '../images/N31/2.jpg';
// import N316 from '../images/N31/3.jpg';

// import R42P1 from '../images/R42P/images/汉印R42P_01.jpg';
// import R42P2 from '../images/R42P/images/汉印R42P_02.jpg';
// import R42P3 from '../images/R42P/images/汉印R42P_03.jpg';
// import R42P4 from '../images/R42P/1.jpg';
// import R42P5 from '../images/R42P/2.jpg';

// import A41 from '../images/A4/images/A4打印机-3_01.jpg';
// import A42 from '../images/A4/images/A4打印机-3_02.jpg';
// import A43 from '../images/A4/images/A4打印机-3_03.jpg';
// import A44 from '../images/A4/images/A4打印机-3_04.jpg';
// import A45 from '../images/A4/1.jpg';
// import A46 from '../images/A4/2.jpg';

// import page1 from '../images/page1/images/便携一联单_01.jpg';
// import page2 from '../images/page1/images/便携一联单_02.jpg';
// import page3 from '../images/page1/images/便携一联单_03.jpg';
// import page4 from '../images/page1/1.jpg';
// import page5 from '../images/page1/2.jpg';

// import page6 from '../images/page2/images/折叠一联单_01.jpg';
// import page7 from '../images/page2/images/折叠一联单_02.jpg';
// import page8 from '../images/page2/images/折叠一联单_03.jpg';
// import page9 from '../images/page2/1.jpg';
// import page10 from '../images/page2/2.jpg';
// import page11 from '../images/page2/3.jpg';

class ProductMessage extends Component {
    constructor(props) {
		super(props)
		this.state = {
        
        }
    }

    componentDidMount(){
        this.props.changeImage('p1');


        // if(!data){
        //     this.props.history.push('/productions');
        // }else{

        //     data.imgsList = data.imgsList.map((item) => {
        //         return item.url;
        //     })
        //     this.setState({
        //         data
        //     })
        // }
    }
    
    render() {
       let { data } = this.props;

        return (
            <div className="product-message">
                <div className="about-company-msg">
                    <div className="company-msg">
                        <p className="biaoti">产品介绍</p>
                        <div style={{height: '26px'}}></div>
                        <p dangerouslySetInnerHTML={{__html:data.description}} />
                    </div>
                    <div className="company-img">
                        {
                            data.imgsList
                                ? <CarouselPage list={data.imgsList}/>
                                : ''
                        }
                        
                    </div>
                </div>
                <div style={{height: '176px'}}></div>
                {/* <Title>产品特点</Title> */}
                <div className='product-info' style={{textAlign:'center'}} dangerouslySetInnerHTML={{__html:data.productDetail}} />
                {/* <div className='feature'>
                    <p>
                        <span>1</span>
                        有效减少热负荷损失，使用更节能，实时监测室内一氧化碳浓度值
                    </p>
                    <p>
                        <span>2</span>
                        有效减少热负荷损失，使用更节能，实时监测室内一氧化碳浓度值
                    </p>
                    <p>
                        <span>3</span>
                        有效减少热负荷损失，使用更节能，实时监测室内一氧化碳浓度值
                    </p>
                    <p>
                        <span>4</span>
                        有效减少热负荷损失，使用更节能，实时监测室内一氧化碳浓度值
                    </p>
                </div>
                <Title>产品参数</Title>
                <Table 
                    dataSource={ [] }
                    columns={ columns }
                    /> */}
			</div>
        )
    }
}

export default ProductMessage;