import React, { Component } from 'react';
import '../less/index.less';
import carousel1 from '../images/carousel1.jpg';
import carousel3 from '../images/carousel3.png';
import Slider from "react-slick";

class CarouselPage extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		let t = this
		var settings = {
			//dots: true,
			//infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay:true,
			accessibility:true
		  };
		
		let list = this.props.list || [
			carousel1,
			carousel3
		];
		
		return (
			<div className="carousel-page">
				 <Slider {...settings}>
				{
					list.map((item) => {
						return (
							<div>
								<img src={item}/>
							</div>
						)
					})
				}
      </Slider>
			</div>
		)
	}
}

export default CarouselPage