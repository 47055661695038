import React, { Component } from 'react';
import '../less/index.less'
import { Tabs,Table } from 'antd';
import ScaleBox from '../components/ScaleBox';
import Ajax from '../utils/Ajax';

const { TabPane } = Tabs;

class Productions extends Component {
    constructor(props) {
		super(props)
		this.state = {
            list:[],
            list1:[] //驱动
        }
    }

    componentDidMount(){
       this.getList('打印设备');
    }

    getList = (websiteClass) => {
        let url = websiteClass == '软件中心' ? 'sjy/equipment/officialwebsite/selectSoftwareListTUser' :'sjy/equipment/officialwebsite/selectProductListTUser'
        Ajax(url,{websiteClass,pageNum:1,pageSize:9})
        .then((res) => {
     
            this.setState({
                list:res.rows,
                list1:res.data
            })
            
        })
    }

     downloadFile(content, filename) {
        var a = document.createElement('a')
        var blob = new Blob([content])
        var url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
    }
    
     download = (url,filename) => {
        let t = this;
        
        this.ajax(url, function(xhr) {
            filename = filename + '.' + url.replace(/(.*\.)/, '') // 自定义文件名+后缀
            t.downloadFile(xhr.response, filename)
        }, {
            responseType: 'blob'
        })
    }
    
     ajax(url, callback, options) {
        window.URL = window.URL || window.webkitURL
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('get', url, true)
        if (options.responseType) {
            xhr.responseType = options.responseType
        }
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
                callback(xhr)
            }
        }
        xhr.send()
    }
    
    
    onClick(index){
        this.props.history.push(
            {
                pathname:'/productMessage',
                state:index
            }
        );
    }

    render() {

        let { list, list1 } = this.state;
        
        const columns = [
            {
              title: '打印机名称',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: '描述',
              dataIndex: 'description',
              key: 'desc',
            },
            {
              title: '系统',
              dataIndex: 'system',
              key: 'system',
            },
            {
              title: '版本',
              dataIndex: 'version',
              key: 'version',
            },
            {
              title: '文件大小',
              dataIndex: 'size',
              key: 'size',
            },
            {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              render:(text,record) => {
                return <a onClick={ () => this.download(record.softwarePackage,record.name)}>下载</a>
              }
            },
        ];
		
        return (
            <div className="productIntroduction">

				<Tabs defaultActiveKey='1' onChange={ this.getList }>
                        <TabPane tab='打印设备' key='打印设备'>
							<div className='product-tab-body'>
								{
									list.map((item,index) => {
										return <ScaleBox 
													img={item.productCover} 
													title={item.name}
													desc={item.productAbstract}
                                                    onClick={() => this.props.history.push({pathname:'/productMessage',state:item})}/>
									})
                    			}  
							</div>
                        </TabPane>
                        <TabPane tab='打印耗材' key='打印耗材'>
                            <div className='product-tab-body'>
								{
									list.map((item,index) => {
										return <ScaleBox 
													img={item.productCover} 
													title={item.name}
													desc={item.productAbstract}
                                                    onClick={() => this.props.history.push({pathname:'/productMessage',state:item})}/>
									})
                    			}  
							</div>
                        </TabPane>
                        <TabPane tab='软件中心' key='软件中心'>
                            <div>
                                <p style={{lineHeight:"40px",fontSize:'20px',borderLeft:'3px solid #386EB1',paddingLeft:'20px'}}>软件下载</p>
                                <ul style={{display:'flex',justifyContent:'space-between'}}>
                                    {
                                        list.map((item,index) => {
                                            return  <li style={{width:'150px',margin:'30px',textAlign:'center'}}>
                                                <img src={item.softwarePackage} alt=""/>
                                                <p style={{textAlign:'center'}}>{item.name}</p>
                                            </li>
                                        })
                                    }
                                </ul>
                                <p style={{lineHeight:"40px",fontSize:'20px',borderLeft:'3px solid #386EB1',paddingLeft:'20px'}}>工具下载</p>
                                <Table columns={columns} dataSource={list1} bordered={true} pagination={false}/>
                            </div>
                        </TabPane>
                    </Tabs>
			</div>
        )
    }
}

export default Productions;