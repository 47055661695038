import React, { Component } from 'react';
import '../less/index.less'

class CoverBox extends Component {
    constructor(props) {
		super(props)
    }
    
    render() {
        let { desc, img, title } = this.props;
        return (
            <div className="cover-box" onClick={this.props.onClick}>
                <div>
                    <img src={ img } alt=""/>
                    <div className="cover-desc">
                        { desc }
                        <i className='icon iconfont icon-youjiantou-'/>
                    </div>
                </div>
                <div>
                    { title }
                </div>
			</div>
        )
    }
}

export default CoverBox;