import React, { Component } from 'react';
import '../less/index.less'
import Title from '../components/Title';
import IdeaForms from '../components/IdeaForm';
import Ajax from '../utils/Ajax';
import cus1 from '../images/cus1.png';

class ConcatUs extends Component {
    constructor(props) {
		super(props)
		this.state = {
            data:{}
        }
    }

    componentDidMount(){
        this.props.changeImage('cus');
        Ajax('sjy/equipment/officialwebsite/queryMainPageContactUs')
            .then((res) => {

                this.setState({
                    data:res.rows[0]
                })
                
            })
        
    }
    
    render() {
        let { data } = this.state;

        return (
            <div className="concatus">
                <div className='concatus-info'>
                    <div className='concatus-info-left'>
                        <div className='concatus-info-left-title'>售前咨询</div>
                        <div>
                            <div>
                                想了解零墨云 开放平台 家印宝app资费和优惠等请拨打联系电话：
                                <span>{data.telephone}</span>
                            </div>
                            <div>
                                售后问题请拨打 售后电话：{data.telephone2}
                            </div>
                            <div>
                                服务时间为周一至周五9:00 - 18:00
                            </div>
                        </div>
                    </div>
                    <div className='concatus-info-right'>
                        <div>售前咨询 &gt;</div>
                    </div>
                </div>
                <div className='concatus-info'>
                    <div className='concatus-info-left'>
                        <div className='concatus-info-left-title'>您的心声我们在聆听</div>
                        <div>
                            零墨云的成长通道，您的建议对我们很重要
                        </div>
                    </div>
                    <div className='concatus-info-right'>
                        <div>去提建议 &gt;</div>
                    </div>
                </div>
                <img style={{width: '100%'}} src={cus1}/>
                {/* <div>
                    <p style={{textAlign:'center',marginTop:'20px',lineHeight:'30px'}}>
                        { data.content }
                    </p>
                    <ul className='company-msg'>
                        <li style={{display:'flex'}}>
                            <i className='icon iconfont icon-filedicon_phone_gre'/>
                            <p>
                                联系电话: {data.telephone} <br />
                                售后电话: {}
                            </p>
                        </li>
                        <li>
                            <i className='icon iconfont icon-youjian'/>
                            技术支持: {data.eMail}
                        </li>
                        <li>
                            <i className='icon iconfont icon-tianchongxing-'/>
                            总部地址：{data.companyAddress}
                        </li>
                    </ul>
                </div>
            */}
			</div>
        )
    }
}

export default ConcatUs;