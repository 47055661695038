import React, { Component } from 'react';
import { Table } from 'antd';
import '../less/index.less'
import xq from '../images/xq.jpg'
import Title from '../components/Title';
import Ajax from '../utils/Ajax';

class AboutUs extends Component {
    constructor(props) {
		super(props)
		this.state = {
            data:{}
        }
    }

    componentDidMount(){
        let data = this.props.location.state;
   
        if(!data){
            this.props.history.push('/corporationCase');
        }else{
            Ajax('sjy/equipment/officialwebsite/selectMainPageArticleById',{id:data.id})
            .then((res) => {

                this.setState({
                    data:res.rows[0]
                })
                
            })
        }
    }
    
    render() {
        let { data } = this.state;

        return (
            <div className="scheme-message">
                <Title>{data.title}</Title>
                <img src={data.backgroundImg} alt=""/>
                <pre dangerouslySetInnerHTML={{__html: data.detail}}>
     
                </pre>
			</div>
        )
    }
}

export default AboutUs;