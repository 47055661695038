import React, { Component } from 'react';
import '../less/index.less'

class AboutUs extends Component {
    constructor(props) {
		super(props)
    }
    
    render() {
        let { img, title, desc } = this.props;

        return (
            <div className="scale-box" onClick={this.props.onClick}>
                <div className='img-box'>
                    <img src={img} alt=""/>
                </div>
                <div className='box-title'>
                    { title }
                </div>
                <div className='box-desc'>
                    { desc }
                </div>
                <a>
                    查看详情 
                    <div className='icon-trans'>
                        <i className='icon iconfont icon-youjiantou-'/>
                    </div>
                </a>
			</div>
        )
    }
}

export default AboutUs;