import React, { Component } from 'react';
import '../less/index.less'
import CoverBox from '../components/CoverBox';
import Ajax from '../utils/Ajax';

class CorporationCase extends Component {

    state = {
        list:[]
    }

    onClick(index){
        this.props.history.push(
            {
                pathname:'/schemeMessage',
                state:index
            }
        );
    }

    componentDidMount(){
        Ajax('sjy/equipment/officialwebsite/selectMainPageArticleList')
            .then((res) => {

                this.setState({
                    list:res.rows
                })
                
            })
    }

    render() {
        let { list } = this.state;
        
        return (
            <div className="corporationCase">
                {
                    list.map((item,index) => {
                        return (
                            <CoverBox 
                                img={ item.articleCoverImg }
                                title={ item.title }
                                desc={ item.articleAbstract }
                                onClick={() => this.props.history.push({pathname:'/schemeMessage',state:item})}/>
                        )
                    })
                }
            
            </div>
        )
    }
}

export default CorporationCase;